<template>
    <div class="admin-content">
        <h3>입사지원서 양식 등록</h3>
        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button class="btn-box green" @click="methods.saveFormInfo">저장</button>
                    <button class="btn-box" @click="eventHandler.moveListClick()">목록</button>
                </div>
            </div>

            <div class="sch-wrap3 mb-5">
                <div>
                    <label>양식명</label>
                    <div>
                        <input type="text" class="mr40 input-text left" v-model="state.dataTable.masterInfo.FORM_NM" />
                    </div>
                </div>
                <div class="chk-box">
                    <input type="checkbox" id="formUseYN" v-model="state.dataTable.masterInfo.USE_YN" />
                    <label for="formUseYN">
                        <p>양식 사용여부</p>
                    </label>
                </div>
                <div>
                    <label>경력구분</label>
                    <div class="select-box">
                        <select v-model="state.dataTable.masterInfo.CARR_DIV_CD">
                            <option value=''>선택</option>
                            <option v-for="item in state.dataTable.carrierCodeList" v-bind:value="item.CodeVal" v-bind:key="item" v-text="item.CodeName" />
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <DataTable class="p-datatable-sm" columnResizeMode="fit" scrollHeight="497px" :value="state.dataTable.formItems"
            :resizableColumns="true" scrollable>

            <template #empty>
                데이터가 없습니다.
            </template>

            <Column field="FORM_DIV_CD_NM" header="구분" :sortable="true" style="flex: 0 0 28rem" />
            <Column field="FORM_CONT_CD_NM" header="항목" :sortable="true" />
            <Column field="ARTCL_USE_YN" header="항목 사용여부" :sortable="true" style="flex: 0 0 11rem;" bodyStyle="text-align: center">
                <template #body="{ data }">
                    <div class="chk-box">
                        <input type="checkbox" name="artclUseYN" v-model="data.ARTCL_USE_YN" :binary="true"
                            @change="eventHandler.changeYN(data)" />
                        <label></label>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import { reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
import { form } from '@/modules/recruit/REC_RC0110D.js';
import { common } from '@/modules/common/common.js';
import alertManager from '@/utils/alert-confirm-manager';
import router from '@/router';

export default {
    setup() {
        // 스토어
        const store = useStore();

        // 라우트
        const vrt = useRoute();

        // state
        const state = reactive({

            parameter: {

                // 양식 번호
                formNo: !!vrt.query.FormNo ? vrt.query.FormNo : null
            },

            dataTable: {

                // 신규 등록 시 양식마스터 정보 초기화
                masterInfo: {

                    // 양식번호
                    'FORM_NO': 0,

                    // 양식명
                    'FORM_NM': '',

                    // 양식마스터 사용여부
                    'USE_YN': 'Y',

                    // 경력구분 코드
                    'CARR_DIV_CD': ''
                },

                // 양식 상세 항목
                formItems: new Array(),

                // 경력구분 리스트
                carrierCodeList: new Array()
            },

            // 사용여부 변경 테이블
            changeYNTable: new Array()
        });

        // 메소드
        const methods = {

            // 양식 상세 조회
            getFormDetail: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                // 상세화면 formNo로 데이터테이블 세팅
                form.getFormDetail(state.parameter.formNo).then(result => {

                    if (!!result.Table.rows[0]) state.dataTable.masterInfo = result.Table.rows[0];
                    state.dataTable.formItems = result.Table1.rows;

                }).finally(() => {

                    if (state.dataTable.masterInfo.USE_YN === 'Y') state.dataTable.masterInfo.USE_YN = true
                    else state.dataTable.masterInfo.USE_YN = false

                    state.dataTable.formItems.forEach(row => {
                        row.ARTCL_USE_YN = row.ARTCL_USE_YN === 'Y' ? true : false;
                    })

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            // 양식 저장
            saveFormInfo: () => {

                // 유효성 검사

                // 양식명
                let formTitle = '';
                // 경력지원자 전용
                let careerValidation = false;
                // 신입지원자 전용
                let newcomerValidation = false;

                formTitle = state.dataTable.masterInfo.FORM_NM.trim();

                // 양식명 미입력
                if(!formTitle) {

                    alertManager.alert.warning("양식명을 입력해주세요.");
                    return false;
                }

                if(state.selectedCarrierCode === ''){

                    alertManager.alert.warning("경력구분을 선택해주세요.");
                    return false;
                }

                state.dataTable.formItems.forEach(item => {

                    if(item.FORM_CONT_CD == 'RC005305') careerValidation = item.ARTCL_USE_YN
                    else if (item.FORM_CONT_CD == 'RC005310') newcomerValidation = item.ARTCL_USE_YN
                });

                // 자기소개서 미선택
                if (careerValidation === false && newcomerValidation === false) {

                    alertManager.alert.warning("자기소개서 유형을 선택해주세요.");
                    return false;
                }

                // 자기소개서 중복 선택
                if(careerValidation === true && newcomerValidation === true) {

                    alertManager.alert.warning("자기소개서 유형을 하나만 선택해주세요.");
                    return false;
                } 

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                // 양식마스터 사용여부 저장
                if (state.dataTable.masterInfo.USE_YN === true) state.dataTable.masterInfo.USE_YN = 'Y'
                else state.dataTable.masterInfo.USE_YN = 'N'

                // 양식상세 항목 사용여부 저장(수정된 값이 있을 경우)
                if (!!state.changeYNTable) {
                    state.changeYNTable.forEach(row => {
                        if (row.ARTCL_USE_YN === true) row.ARTCL_USE_YN = 'Y'
                        else row.ARTCL_USE_YN = 'N'
                    });
                }

                // 양식마스터 기본정보
                let masterInfoJson = JSON.stringify(state.dataTable.masterInfo);

                // 양식 상세 수정된 항목
                let changeInfoJson = !!state.changeYNTable ? JSON.stringify(state.changeYNTable) : null

                form.saveFormInfo(masterInfoJson, changeInfoJson).then((response) => {

                    if (response.success) {

                        alertManager.alert.success('저장되었습니다.')
                    }
                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 목록으로 이동
                    router.push({ name: 'REC_RC0100R' });
                });
            }
        };

        // 이벤트 핸들러
        const eventHandler = {

            moveListClick: () => {

                router.push({ name: 'REC_RC0100R' });
            },

            // 양식상세 항목 사용여부 체크
            changeYN: (data) => {

                let changeData = {
                    // 구분
                    "FORM_DIV_CD": data.FORM_DIV_CD,
                    // 항목
                    "FORM_CONT_CD": data.FORM_CONT_CD,
                    // 항목 사용여부
                    "ARTCL_USE_YN": data.ARTCL_USE_YN
                };

                // 사용여부 체크 시 같은 항목 중복입력 방지
                // some 함수로 중복된 값이 있는지 체크
                var check = state.changeYNTable.some(v => v.FORM_CONT_CD === changeData.FORM_CONT_CD);

                // 중복된 값이 없을때
                if (!check) state.changeYNTable.push(changeData)

                // 중복된 값이 있을때 (중복된 값을 제외한 나머지만 저장)
                else state.changeYNTable = state.changeYNTable.filter(item => item.FORM_CONT_CD != data.FORM_CONT_CD);
            }
        };

        onMounted(() => {

            // 양식 상세 초기조회
            methods.getFormDetail();

            common.getSystemCodeInfo('CODEINFO','CD021000').then((result) => {
                
                state.dataTable.carrierCodeList = result.splice(0,2)
            });
        });

        return { state, methods, eventHandler, store, common }
    }
}
</script>